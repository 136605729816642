import React from 'react';

const Textarea = ({resize = 'both', ...props}) => {
    const resizeClass = {
        none: 'resize-none',
        x: 'resize-x',
        y: 'resize-y',
        both: 'resize',
    }[resize];

    return (
        <textarea
            className={`bg-white border border-input text-sm p-1 rounded-md w-full min-h-32 ${resizeClass}`}
            placeholder="Pište..."
            {...props}
        >
        {props.text}
    </textarea>
    );
};

export default Textarea;
