import React from 'react';

const Button = ({ variant = 'primary', icon: Icon, iconPosition = 'left', children, className = '', ...props }) => {
  const baseStyles = 'h-11 transition-all px-3 rounded-lg font-medium focus:outline-none inline-flex items-center';
  
  const variantStyles = 
    variant === 'primary' ? 'bg-green-800 text-white hover:bg-green-700' :
    variant === 'secondary' ? 'bg-white text-black hover:bg-white/50' :
    variant === 'outlined' ? 'border border-gray-800 text-gray-800 hover:bg-gray-100' :
    '';

  return (
    <button
      className={`${baseStyles} ${variantStyles} ${className}`}
      {...props}
    >
      {Icon && iconPosition === 'left' && <Icon className="mr-2" />}
      {children}
      {Icon && iconPosition === 'right' && <Icon className="ml-2" />}
    </button>
  );
};

export default Button;
