import React, { useState, useEffect, useRef } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

function cn(...inputs) {
  return twMerge(clsx(inputs));
}

const Select = React.forwardRef(function CustomSelect({ className, options = [], onChange, ...props }, ref) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const selectRef = useRef(null);

  const handleSelect = (option) => {
    setSelectedOption(option);
    if (onChange) {
      onChange(option);
    }
    setTimeout(() => setIsOpen(false), 10);
  };

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full" ref={selectRef}>
      <button
        type="button"
        className={cn(
          "flex h-11 w-44 items-center justify-between rounded-md border border-gray-300 bg-transparent px-3 py-2 text-black focus:outline-none focus:ring-2 focus:ring-blue-500",
          className
        )}
        onClick={toggleDropdown}
        ref={ref}
        {...props}
      >
        <span className="line-clamp-1">{selectedOption ? selectedOption.label : "Select an option"}</span>
        <KeyboardArrowDownIcon className="text-gray-500" />
      </button>

      {isOpen && (
        <ul className="absolute z-10 mt-1 w-full rounded-md border border-gray-300 bg-white shadow-lg p-1">
          {options.map((option) => (
            <li
              key={option.value}
              className="cursor-pointer px-3 py-2 text-black hover:bg-gray-100 rounded-md break-words "
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
});

export { Select };