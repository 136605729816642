import { Button, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import Dialog from "../controls/Dialog";
import {useTranslation} from "react-i18next";

const StyledSpan = styled(Typography)({
    color: 'black',
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    padding: '2px 6px',
    marginLeft:'2px'
});

const TestResultsDialog = (props) => {
    const { open, setOpen, report, infoMessages} = props;
    const progressBarPercentSize=  {
        width: '100%',
    };
    const {t} = useTranslation();
    return (
        <React.Fragment>
            <Dialog
                title={t("Test results")}
                open={open}
                setOpen={setOpen}
                textAlign="center">
                <DialogContent
                    sx={{ textAlign: "center" }}>

                    <div className="wrapper">
                        <div className="progress-bar">
                            <span className="progress-bar-fill" style={progressBarPercentSize}>100%</span>
                        </div>
                    </div>
                    <Typography
                        display="block"
                        component="h5">
                        {t("Test runs successfully, found conflicts:")}
                    </Typography>
                    <br />
                    {report ?
                        <Grid container>
                            <Typography
                                sx={{ color: 'rgba(255, 167, 38, 0.5)' }}
                                variant="body1">
                                {t("Difference in first intent:")}
                                <StyledSpan
                                    variant="span"
                                    style={{ backgroundColor: 'rgba(255, 167, 38, 0.5)' }}>
                                    {report.testDatasetIntentConflicts}
                                </StyledSpan>
                            </Typography>
                            <Typography
                                sx={{ color: 'rgba(2, 136, 209, 0.5)' }}
                                variant="body1">
                                {t("Difference in confidence first and second intent smaller then 40%:")}
                                <StyledSpan
                                    variant="span"
                                    style={{ backgroundColor: 'rgba(2, 136, 209, 0.5)' }}>
                                    {report.testIntentsConfidenceConflicts}
                                </StyledSpan>
                            </Typography>
                            <Typography
                                sx={{ color: 'rgba(211, 47, 47, 0.5)' }}
                                variant="body1">
                                {t("Difference between answer ID")}
                                <StyledSpan
                                    variant="span"
                                    style={{ backgroundColor: 'rgba(211, 47, 47, 0.5)' }}>
                                    {report.testDataSetResponseIdConflicts}
                                </StyledSpan>
                            </Typography>
                            <code style={{ height: '250px', width: '100%', overflow:'auto', msScrollbarBaseColor:'gold', fontFamily:'sans-serif', padding:'10px', textAlign: 'left' }}>
                                <br/>
                                <b>Test log:</b>
                                <br/>
                                { infoMessages.map(message => <div>{message}</div>) }
                            </code>
                        </Grid>
                        : <Typography>{t("Unable to create report")}</Typography>}
                </DialogContent>
                <DialogActions
                    sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                    <Button
                        variant="contained"
                        sx={{ borderRadius: '10px' }}
                        onClick={() => setOpen(false)}>
                        {t("Show results")}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default TestResultsDialog;