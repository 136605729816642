import React, {useEffect, useState} from 'react';
import {Compare, SwapHoriz, Settings, GridView} from '@mui/icons-material';
import {useNavigate} from "react-router-dom";
import * as Api from "../../api/Api";

const Sidebar = () => {
    const [selectedOption, setSelectedOption] = useState('NLP');
    const [activeItem, setActiveItem] = useState('Přehled');
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedOption === 'LLM') {
            handleNavigation("Testování sad", Api.LLM_SETS_TESTING, "LLM")
        } else if (selectedOption === 'NLP') {
            handleNavigation('Přehled', "/", "NLP")
        }
        // eslint-disable-next-line
    }, [selectedOption])

    const handleNavigation = (item, path, section) => {
        setActiveItem(item);
        setSelectedOption(section);
        navigate(path);
    };

    const nlpMenu = () => {
        return (
            <div className="mt-4 flex flex-col gap-1.5">
                <button
                    onClick={() => handleNavigation('Přehled', '/', "NLP")}
                    className={`text-black no-underline flex text-sm items-center px-2 py-2.5 rounded-lg transition-all cursor-pointer ${
                        activeItem === 'Přehled' ? 'bg-[#A5CF80] hover:bg-[#A5CF80]' : 'bg-[#F5F8F3] hover:bg-[#a5cf8033]'
                    }`}
                >
                    <GridView style={{fontSize: '20px', marginRight: '8px'}}/>
                    <span>Overview</span>
                </button>
                <button
                    onClick={() => handleNavigation('Testování sad', Api.SETS_TESTING, "NLP")}
                    className={`flex text-sm items-center px-2 py-2.5 rounded-lg transition-all cursor-pointer ${
                        activeItem === 'Testování sad' ? 'bg-[#A5CF80] hover:bg-[#A5CF80]' : 'bg-[#F5F8F3] hover:bg-[#a5cf8033]'
                    }`}
                >
                    <SwapHoriz style={{fontSize: '20px', marginRight: '8px'}}/>
                    <span>Testing</span>
                </button>
                <button
                    onClick={() => handleNavigation('Porovnání testů', Api.TESTS_SUMMARY, "NLP")}
                    className={`flex text-sm items-center px-2 py-2.5 rounded-lg transition-all cursor-pointer ${
                        activeItem === 'Porovnání testů' ? 'bg-[#A5CF80] hover:bg-[#A5CF80]' : 'bg-[#F5F8F3] hover:bg-[#a5cf8033]'
                    }`}
                >
                    <Compare style={{fontSize: '20px', marginRight: '8px'}}/>
                    <span>Test comparison</span>
                </button>
                <button
                    onClick={() => handleNavigation('Správa sad', Api.SETS_MANAGEMENT, "NLP")}
                    className={`flex text-sm items-center px-2 py-2.5 rounded-lg transition-all cursor-pointer ${
                        activeItem === 'Správa sad' ? 'bg-[#A5CF80] hover:bg-[#A5CF80]' : 'bg-[#F5F8F3] hover:bg-[#a5cf8033]'
                    }`}
                >
                    <Settings style={{fontSize: '20px', marginRight: '8px'}}/>
                    <span>Set management</span>
                </button>
            </div>
        )
    }

    const llmMenu = () => {
        return (
            <div className="mt-4 flex flex-col gap-1.5">
                <button
                    onClick={() => handleNavigation('Přehled', '/', "NLP")}
                    className={`text-black no-underline flex text-sm items-center px-2 py-2.5 rounded-lg transition-all cursor-pointer ${
                        activeItem === 'Testování sad' ? 'bg-[#A5CF80] hover:bg-[#A5CF80]' : 'bg-[#F5F8F3] hover:bg-[#a5cf8033]'
                    }`}
                >
                    <GridView style={{fontSize: '20px', marginRight: '8px'}}/>
                    <span>Testing set</span>
                </button>
            </div>
        )
    }

    return (
        <div className="h-full min-w-[15rem] max-w-[15rem] my-2 ml-2 rounded-lg bg-[#F9F9F9] p-2">
            <div className="relative flex items-center justify-center p-1 rounded-lg w-full bg-[#E6EDE2]">
                <div
                    className={`absolute top-1 bottom-1 w-1/2 rounded-md bg-white transition-transform duration-300 ease-in-out ${selectedOption === 'LLM' ? 'translate-x-full -left-1' : 'translate-x-0 left-1'}`}
                ></div>
                <label
                    className={`relative flex-1 text-center py-1.5 rounded-md text-sm cursor-pointer z-10 ${selectedOption === 'NLP' ? 'text-black' : 'text-gray-600'}`}
                    onClick={() => setSelectedOption('NLP')}
                >
                    NLP
                </label>
                <label
                    className={`relative flex-1 text-center py-1.5 rounded-md text-sm cursor-pointer z-10 ${selectedOption === 'LLM' ? 'text-black' : 'text-gray-600'}`}
                    onClick={() => setSelectedOption('LLM')}
                >
                    LLM
                </label>
            </div>
            {selectedOption === "NLP" ? nlpMenu() : llmMenu()}
        </div>
    );
}

export default Sidebar;
