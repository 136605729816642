import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App'; // Corrected import path for App
import 'typeface-roboto';
import './i18n'; // Ensure this path is correct

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <App />
);

reportWebVitals();