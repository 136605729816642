export const API_V1_PREFIX = "/api/v1"
export const PROJECT_ID_PARAM = "projectId"
export const DATASET_ID_PARAM = "dataSetId"
export const PATH_PROJECTS = `${API_V1_PREFIX}/projects`
export const PATH_DATASETS = `${API_V1_PREFIX}/datasets`
export const SUBPATH_DATASET = `/${DATASET_ID_PARAM}`
export const SUBPATH_DATASET_ARCHIVE = `/archive`
export const SUB_PATH_CLIENTS = "/clients"

export const SUB_PATH_DATASETS = "/datasets"

export const PATH_SYSTEM = `${API_V1_PREFIX}/system`
export const SUB_PATH_SYSTEM_VALUES = `${PATH_SYSTEM}/values`

export const PATH_TESTS = `${API_V1_PREFIX}/tests`
export const PATH_TESTS_HISTORY = `${PATH_TESTS}/history`
export const PATH_TEST_RESULTS = `${PATH_TESTS}/test-results`
export const PATH_TEST_RESULTS_EXPORT = `${PATH_TESTS}/export`

// Component routes
export const SETS_MANAGEMENT = "sets-management"
export const SETS_TESTING = "sets-testing"
export const TESTS_SUMMARY = "/tests-summary"
export const LLM_SETS_TESTING = "llm-sets-testing"
