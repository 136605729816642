import {Box} from "@mui/material";
import React, {useEffect, useState} from "react";

export default function WaitingPage({string}) {
    const [str, setStr] = useState("")
    useEffect(() => {
        if(string.length >0) setStr(string)
        else setStr("Running")
    }, [string]);
    return (
        <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "absolute",
            backgroundColor: "transparent",
        }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: "20%",
                height: "20rem",
                justifyContent: "space-around",
                alignItems: "center",
                backgroundColor: "white",
                borderRadius: "1rem",
                padding: "3rem"
            }}>
                <h1>{str}</h1>
                <Box
                    component="img"
                    alt="Running foxie"
                    src="/running-foxie.gif"
                    sx={{
                        width: "180px",
                        height: "180px",
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }}
                />
            </div>
        </div>
    )
}