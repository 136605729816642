import React from "react";
import ProjectSelect from "../ProjectSelect/ProjectSelect";


const Header = (props) => {
    return (
        <div className="header">
            <img alt="Main Logo" src="/logo.png" width={100} className="p-1.5" />
            <h1 className="text-xl ml-auto text-center text-white font-normal mb-0">Testing tool</h1>

                <ProjectSelect />
        </div>
    )
};

export default Header;