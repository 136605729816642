import * as Api from '../api/Api';
import {axiosClient} from '../api/TestingFoxie';

export const getAll = (projectId, page, pageSize) => {
    return axiosClient.get(`${Api.PATH_PROJECTS}/${projectId}${Api.SUB_PATH_DATASETS}?page=${page}&pageSize=${pageSize}&total=true`);
};

export const uploadFile = (projectId, file) => {
    return axiosClient.post(`${Api.PATH_PROJECTS}/${projectId}${Api.SUB_PATH_DATASETS}`, file)
}

export const archive = (dataSetId, projectId) => {
    return axiosClient.put(`${Api.PATH_DATASETS}/${dataSetId}${Api.SUBPATH_DATASET_ARCHIVE}?projectId=${projectId}`)
}

export const runTest = (dataSetId, projectId, clientId, keepSession, emitterId) => {
    return axiosClient.post(`${Api.PATH_TESTS}?projectId=${projectId}&emitterId=${emitterId}`,
        {
            dataSetId: dataSetId,
            clientId: clientId,
            keepSession: keepSession
        });
}

export const getTestResult = (ids, projectId) => {
    return axiosClient.get(`${Api.PATH_TESTS}?testResultIds=${ids}&projectId=${projectId}`);
}

export const updateDataSet = (dataSetItems, projectId) => {
    return axiosClient.put(Api.PATH_DATASETS + `?projectId=${projectId}`, dataSetItems);
}

export const updateTestResult = (testResult, projectId) => {
    return axiosClient.put(Api.PATH_TESTS + `?projectId=${projectId}`, testResult);
}

export const getHistoricalTestResult = (dataSetId, projectId, clientId, conflictsOnly = false, page, pageSize) => {
    return axiosClient.get(`${Api.PATH_TESTS_HISTORY}?dataSetId=${dataSetId}&projectId=${projectId}&clientId=${clientId}&conflictsOnly=${conflictsOnly}&page=${page}&pageSize=${pageSize}`);
}

export const getTestResults = (dataSetId, projectId, clientId, page, pageSize, testsCount, withItems, withResultsCount = false) => {
    return axiosClient.get(`${Api.PATH_TEST_RESULTS}?dataSetId=${dataSetId}&projectId=${projectId}&clientId=${clientId}&page=${page}&pageSize=${pageSize}&testsCount=${testsCount}&withItems=${withItems}&withResultsCount=${withResultsCount}`);
}

export const exportTestResults = (ids, projectId) => {
    return axiosClient.get(`${Api.PATH_TEST_RESULTS_EXPORT}?testResultIds=${ids}&projectId=${projectId}`, {responseType: 'arraybuffer'});
}

export const listLlmDataSets = (projectId) => {
    return axiosClient.get(`api/v1/llm-controller/list-test-sets/${projectId}`)
}

export const deleteTestSet = (projectId, testSetId) => {
    return axiosClient.delete(`api/v1/llm-controller/test-set/${projectId}?llmDataSetId=${testSetId}`)
}
export const getTestSet = (projectId, testSetId) => {
    return axiosClient.get(`api/v1/llm-controller/test-set/${projectId}?llmDataSetId=${testSetId}`)
}
export const putTestSet = (projectId, testSetId, testName, promptId, testSet) => {
    return axiosClient.put(`api/v1/llm-controller/test-set/${projectId}?llmDataSetId=${testSetId}&name=${testName}&promptId=${promptId}`, testSet)
}
export const postTestSet = (projectId, testName, promptId = "", testSet = "") => {
    return axiosClient.post(`api/v1/llm-controller/test-set/${projectId}?testSet=${testSet}&name=${testName}&promptId=${promptId}`, [])
}

export const executeTest = (projectId, testSetId) => {
    return axiosClient.put(`api/v1/llm-controller/execute-test/${projectId}/?llmDataSetId=${testSetId}`)
}

export const getTest = (projectId, resultId) => {
    return axiosClient.get(`api/v1/llm-controller/test-result/${projectId}?llmTestResultId=${resultId}`)
}
export const listResults = (projectId, id) => {
    return axiosClient.get(`api/v1/llm-controller/list-test-results/${projectId}?llmTestItemId=${id}`)
}

export const executeCopilot = (projectId, first, second, prompt, model) => {
    return axiosClient.put(`api/v1/llm-controller/batch-execute-copilot/${projectId}?firstResultId=${first}&secondResultId=${second}&llmModel=${model}`, {prompt: prompt})
}