import {Button, Input} from "@mui/material";
import {useState} from "react";


export default function CreationPopUp({save, close, testName, prompt}) {
    const [name, setName] = useState(testName ? testName : "");
    return (
        <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "absolute",
            backgroundColor: "transparent",
        }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: "20%",
                height: "20rem",
                justifyContent: "space-around",
                backgroundColor: "white",
                borderRadius: "1rem",
                padding: "3rem"
            }}>
                <label>Set name:</label>
                <Input value={name} onChange={(e) => {
                    setName(e.target.value)
                }}/>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-around",
                }}>
                    <Button onClick={() => {
                        save(name, "")
                        close()
                    }} variant={"contained"}>Save</Button>
                    <Button onClick={() => {
                        close()
                    }} variant={"contained"} color={"grey"}>Close</Button>
                </div>
            </div>
        </div>
    )
}