import {
    Button,
    Checkbox, CircularProgress,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid, Popover,
    styled,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import * as dataSetService from "../services/dataSetService";
import * as formattingUtils from "../utils/formattingUtils";
import FormControl from "@mui/material/FormControl";
import {useTranslation} from "react-i18next";

const StyledSpanKey = styled(Typography)({
    color: '#999999',
});
const StyledSpanValue = styled(Typography)({
    color: '#333333',
    marginRight: '10px',
    maxWidth: '270px',
    display: 'inline-block'
});

const TestsExport = (props) => {
    const {dataSet, project, client, container, showError} = props;
    const [testResults, setTestResults] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [testName, setTestName] = React.useState("");
    const [checked, setChecked] = React.useState([]);
    const [dataFetched, setDataFetched] = useState(true);
    const [testExport, setTestExport] = useState(false);
    const {t} = useTranslation()

    useEffect(() => {
        if (dataSet && project && client)
            getTestResults()
        //eslint-disable-next-line
    }, [dataSet, project, client]);

    const createLabel = (result) => {
        let testName = result.name;
        if (result.name.length > 30) {
            testName = `${result.name.substring(0, 20)}...${result.name.substring(result.name.length - 10, result.name.length)}`;
        }
        return (<React.Fragment>
            <StyledSpanValue
                onMouseEnter={(event) => {
                    setAnchorEl(event.currentTarget);
                    setTestName(result.name)
                }}
                onMouseLeave={() => setAnchorEl(null)}
                variant="span">{(result.name === "") ? "Undefined name" : testName}</StyledSpanValue>
            <StyledSpanKey variant="span">{formattingUtils.formatTimestamp(result.executed)}</StyledSpanKey>
        </React.Fragment>)
    }

    const getTestResults = () => {
        setDataFetched(false);
        dataSetService.getTestResults(dataSet, project.id, client, 0, 10, 10, false)
            .then((response) => {
                const result = response.data;
                if (result) {
                    setTestResults(result.items)
                }
            }).catch(error => {
            showError(error);
        }).finally(() => {
            setDataFetched(true);
        });
    }

    const exportTestResults = () => {
        setTestExport(true)
        dataSetService.exportTestResults(checked, project.id)
            .then(response => {
                const filename = response.headers["content-disposition"].split('filename=')[1];
                let url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
            }).catch(error => {
            showError(error);
        }).finally(() => {
            setTestExport(false);
        });
    }

    const handleChange = (event, item) => {
        if (event.target.checked === false)
            setChecked(checked.filter(it => it !== item.id))
        else
            setChecked([...checked, item.id]);
    };

    return (
        <React.Fragment>
            <Grid container
                  sx={{
                      border: '2px solid #58A813',
                      borderRadius: '4px'
                  }}>

                <FormControl sx={{m: 1}}>
                    {!dataFetched ?
                        <React.Fragment>
                            <FormLabel component="legend"> {t("Choose tests for export:")}</FormLabel>
                            <CircularProgress size='2vh'/>
                        </React.Fragment> :
                        dataFetched && testResults.length === 0 ?
                            <React.Fragment>
                                <FormLabel component="legend"> {t("Choose tests for export:")}</FormLabel>
                                <FormLabel component="legend"> {t("No results found")}</FormLabel>
                            </React.Fragment> :
                            dataFetched && testExport ?
                                <React.Fragment>
                                    <FormLabel component="legend">{t("Exporting sets")}</FormLabel>
                                    <CircularProgress comp size='2vh'/>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <FormGroup>
                                        <FormLabel component="legend"> {t("Choose tests for export:")}</FormLabel>
                                        {testResults.map(it => (
                                            <React.Fragment>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        onChange={(event) => handleChange(event, it)}/>}
                                                    label={createLabel(it)}/>
                                            </React.Fragment>
                                        ))}
                                    </FormGroup>
                                    <Button
                                        sx={{backgroundColor: '#58A813'}}
                                        size="small"
                                        variant="contained"
                                        onClick={() => exportTestResults()}
                                    >Export</Button>
                                </React.Fragment>
                    }
                </FormControl>
            </Grid>
            <Popover
                open={Boolean(anchorEl && testName.length > 30)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                sx={{
                    pointerEvents: 'none'
                }}
                {...container && {container: props.container}}
            >
                {testName}
            </Popover>
        </React.Fragment>
    )
}

export default TestsExport;