import React from 'react';
import { Menu } from '@mui/material';


const ProjectsMenu = (props) => {
    const { anchorEl, setAnchorEl, open, setOpen, projects, setSelectedProject } = props;
    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const handleChange = (project) => {
        setSelectedProject(project)
    };

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    transform: 'translateX(0%) translateY(0%)',
                    borderRadius: '8px',
                    padding: '0.75rem',
                    boxShadow: '0 3px 10px rgb(0 0 0 / 0.1)'
                    
                }
            }}
        >
            {projects ?
                projects.map((project) => (
                    <div
                    className='py-1.5 hover:bg-gray-50 cursor-pointer px-3 rounded-md'
                        key={project.id}
                        value={project.id}
                        onClick={() => {
                            handleChange(project);
                            handleClose();
                        }}
                    >{project.name}</div>)
                ) : null
            }
        </Menu>
    )
}

export default ProjectsMenu;