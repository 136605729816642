import React, {useState, useRef} from 'react';
import Textarea from '../controls/Textarea';
import Button from "../controls/Button";
import {Select} from "../controls/Select";

function cn(...classes) {
    return classes.filter(Boolean).join(' ');
}

const Slider = React.forwardRef(({className, onChange, value = 0, ...props}, ref) => {
    const sliderRef = useRef(null);
    const [sliderValue, setSliderValue] = useState(value);

    const handleSliderChange = (e) => {
        const rect = sliderRef.current.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const newValue = Math.max(0, Math.min(1, (offsetX / rect.width)));
        setSliderValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <div
            ref={ref}
            className={cn(
                "relative flex w-full touch-none select-none items-center",
                className
            )}
            onMouseDown={handleSliderChange}
            onMouseMove={(e) => e.buttons && handleSliderChange(e)}
            {...props}
        >
            <div ref={sliderRef} className="relative h-2 w-full grow overflow-hidden rounded-full bg-gray-300">
                <div
                    className="absolute h-full bg-green-700"
                    style={{width: `${sliderValue * 100}%`}}
                />
            </div>
            <div
                className="block h-5 w-5 rounded-full border-2 border-green-700 bg-green-700 ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
                style={{position: "absolute", left: `${sliderValue * 100}%`, transform: "translateX(-50%)"}}
            />
        </div>
    );
});

Slider.displayName = "Slider";

const Autopilot = ({runCopilot, prompt, setPrompt, model, setModel}) => {

    const options = [
        {
            label: "GPT 4o",
            value: "gpt-4o"
        },
        {
            label: "GPT 3.5 TURBO",
            value: "gpt-35-turbo"
        },
        {
            label: "GPT 4",
            value: "gpt-4"
        }

    ]


    return (
        <div className='flex flex-col gap-6 text-sm w-2/12'>
            <h1 className='mb-0 text-sm'>AddAI Autopilot</h1>
            <div className='flex flex-col gap-1'>
                <span className='font-medium'>Comparison prompt</span>
                <Textarea className="bg-transparent border rounded-lg min-h-40 p-1" text={prompt} onChange={(e) => {
                    setPrompt(e.target.value)
                }}/>
            </div>
            <div className='flex flex-col gap-1'>
                <span className='font-medium'>Select model for comparison</span>
                <Select options={options} value={model} onChange={(e)=>{setModel(e.value)}}/>
            </div>
            <div className='flex flex-col gap-1'>
                <Button style={{backgroundColor: "#672957", color: "white"}}
                        onClick={() => {
                            runCopilot()
                        }}>
                    <img style={{height: "2rem"}} alt={"autopilot"} src={"ai_avatar.png"}/>
                    Run autopilot
                </Button>
            </div>
        </div>
    );
}

export default Autopilot;
