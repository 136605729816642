import {Button, DialogActions, DialogContent, Typography} from "@mui/material";
import React from "react";
import Dialog from "../controls/Dialog";
import {useTranslation} from "react-i18next";

const ConfirmDialog = (props) => {
    const {open, setOpen, title, subtitle, confirmAction, isDownloadable = false, href, download} = props;
    const {t} = useTranslation();
    return (
        <React.Fragment>
            <Dialog
                title={title}
                textAlign="center"
                open={open}
                setOpen={setOpen}>
                <DialogContent sx={{textAlign: "center"}}>
                    <Typography variant="subtitle2">
                        {subtitle}
                    </Typography>
                </DialogContent>
                <DialogActions sx={{display: 'flex', justifyContent: 'center'}}>
                    {isDownloadable === false ?
                        <Button
                            variant="contained"
                            sx={{borderRadius: '10px'}} onClick={
                            () => {
                                confirmAction();
                                setOpen(false);
                            }}
                        >
                            Potvrdit
                        </Button>
                        :
                        <Button
                            variant="contained"
                            sx={{borderRadius: '10px'}}
                            onClick={() => setOpen(false)}
                        >
                            <a style={{textDecoration: 'none', color: 'inherit'}} href={href}
                               download={download}>{t("Download")}</a>
                        </Button>
                    }
                    <Button
                        variant="outlined"
                        sx={{borderRadius: '10px'}}
                        onClick={() => setOpen(false)}
                    >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default ConfirmDialog;